import Carousel from './Carousel.js';
import React from 'react'
import Post from './Poste.js';

export default function Home() {
    const images=[
        "https://scontent.ftun15-1.fna.fbcdn.net/v/t39.30808-6/417453584_122108172740220042_2137510418523989609_n.png?_nc_cat=110&ccb=1-7&_nc_sid=5f2048&_nc_ohc=4AeypyZSFAAAX-XudMK&_nc_ht=scontent.ftun15-1.fna&oh=00_AfDz0KADm8kSs9DM53fkffYGKF9hXG9-dd7YxZUdhjSHkw&oe=65FC7D7D",
         "https://www.maximatecc.com/wp-content/uploads/2017/02/White-Big-Shuttle-Bus-CMYK.jpg",
         "https://scontent.ftun1-2.fna.fbcdn.net/v/t39.30808-6/417436517_904026751724562_7770166546348885781_n.jpg?stp=cp6_dst-jpg&_nc_cat=104&ccb=1-7&_nc_sid=dd5e9f&_nc_ohc=ev3Suu5u-YIAX_NNHzd&_nc_ht=scontent.ftun1-2.fna&oh=00_AfAz1vENA9mWM9f40gUjdJS9noxacVhZ2NAqvTD_aShJ6A&oe=65CE905C",
         "https://i.pinimg.com/564x/a1/d5/05/a1d50592f5ffea8a25acde18d8b9feb7.jpg"
    
      ]
  return (
    <div>
      <Carousel images={images}/>
      <Post />
    </div>
  )
}
